<template>
  <CLoading :loading="loading" />
  <q-form ref="formRef" greedy>
    <div class="max-height-dialog">
      <TestModeSelector v-model="isTestMode" @update:detailCopy="onDetailCopy" v-if="form._id !== ''" />
      <label class="text-grey-9 text-bold">{{ $t('label.rich_menu.name') }}</label>
      <q-input
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || requiredRule.name]"
        outlined
        v-model="form.name"
        dense
        :placeholder="$t('label.rich_menu.name')"
      >
      </q-input>

      <label class="text-grey-9 text-bold">{{ $t('label.rich_menu.chat_bar_text') }}</label>
      <q-input
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || requiredRule.chatBarText]"
        outlined
        v-model="form.test_detail.chat_bar_text"
        dense
        :placeholder="$t('label.rich_menu.chat_bar_text')"
        maxlength="14"
        v-if="isTestMode"
      >
      </q-input>
      <q-input
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || requiredRule.chatBarText]"
        outlined
        v-model="form.prod_detail.chat_bar_text"
        dense
        :placeholder="$t('label.rich_menu.chat_bar_text')"
        maxlength="14"
        v-if="!isTestMode"
      >
      </q-input>
      <RichMenuSelector ref="richMenuSelector" v-if="isTestMode" v-model="form.test_detail.rich_menu_content" />
      <RichMenuSelector ref="richMenuSelector" v-if="!isTestMode" v-model="form.prod_detail.rich_menu_content" />
    </div>
    <q-space class="q-my-md" />
    <div class="row no-wrap items-center">
      <q-space />
      <div class="q-gutter-sm">
        <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
        <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
      </div>
    </div>
  </q-form>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import RichMenuSelector from '@/components/rich-menus/selectors/RichMenuSelector.vue'
import { maska } from 'maska'
import { IRichMenu } from '@/utils/types'
import { NumberUtils } from '@/utils/number'
import { constant } from '@/utils/constants'
import { ACTION_RICH_MENU } from '@/store/actions'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import cloneDeep from 'lodash/cloneDeep'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { CLoading, RichMenuSelector, TestModeSelector },
  directives: { maska },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class RichMenuSettingForm extends mixins(BaseFormMixin) {
  isTestMode = false
  loading = false

  form: IRichMenu = {
    _id: '',
    name: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
    prod_detail: {
      chat_bar_text: '',
      rich_menu_content: [
        {
          label: '',
          rich_menu_alias_id: NumberUtils.uniqueId(),
          size: {
            width: constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH,
            height: constant.RICH_MENU_IMAGE.MAX_IMAGE_HEIGHT,
          },
          tappable_area: [],
          image_url: '',
        },
      ],
    },
    test_detail: {
      chat_bar_text: '',
      rich_menu_content: [
        {
          label: '',
          rich_menu_alias_id: NumberUtils.uniqueId(),
          size: {
            width: constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH,
            height: constant.RICH_MENU_IMAGE.MAX_IMAGE_HEIGHT,
          },
          tappable_area: [],
          image_url: '',
        },
      ],
    },
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedRichMenuId() {
    return this.$route.params.rich_menu_id || ''
  }

  get actionType() {
    return this.$route.params.action_type || ''
  }

  get requiredRule() {
    const requiredRule = {
      name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.rich_menu.name'),
      }),

      chatBarText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.rich_menu.chat_bar_text'),
      }),
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
    }
    return requiredRule
  }

  onDetailCopy() {
    if (this.isTestMode) {
      this.form.test_detail = this.form.prod_detail
    } else {
      this.form.prod_detail = this.form.test_detail
    }
  }

  onCancel() {
    this.goto('rich_menus', { app_id: this.selectedAppId })
  }

  onSubmit() {
    const isValid = this.$refs.richMenuSelector.validate()

    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !isValid) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.loading = true
    const { dispatch } = this.$store
    let item = null
    if (!this.form._id) {
      this.form.is_active = false
      this.form.app_id = this.selectedAppId
      this.form.test_detail = this.form.prod_detail
      item = await dispatch(ACTION_RICH_MENU.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_RICH_MENU.UPDATE, {
        ...this.form,
      })
    }

    if (item) {
      this.loading = false
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.initForm = cloneDeep(this.parentForm)
      this.onCancel()
    } else {
      this.loading = false
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  @Watch('actionType', { immediate: true })
  async fetchRichMenu() {
    if (!this.selectedAppId) {
      return
    }

    if (this.selectedRichMenuId !== '') {
      const data: IRichMenu = await this.$store.dispatch(ACTION_RICH_MENU.LOAD_ITEM, this.selectedRichMenuId)
      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.name = this.form.name + ' Copy'
          this.form.is_test_mode = false
          this.form.is_active = false
          this.form.prod_rich_menu_alias_ids = []
          this.form.prod_rich_menu_ids = []
          this.form.test_rich_menu_alias_ids = []
          this.form.test_rich_menu_ids = []
          if (this.form.prod_detail?.rich_menu_content) {
            for (const item of this.form.prod_detail?.rich_menu_content) {
              item.rich_menu_alias_id = NumberUtils.uniqueId()
            }
          }

          if (this.form.test_detail?.rich_menu_content) {
            for (const item of this.form.test_detail?.rich_menu_content) {
              item.rich_menu_alias_id = NumberUtils.uniqueId()
            }
          }
        }
      }
      this.parentForm = this.form
      this.initForm = cloneDeep(this.parentForm)
    }
  }

  async created() {
    await this.fetchRichMenu()

    if (this.selectedRichMenuId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
