import { render } from "./CampaignSettingForm.vue?vue&type=template&id=d166f130&scoped=true"
import script from "./CampaignSettingForm.vue?vue&type=script&lang=ts"
export * from "./CampaignSettingForm.vue?vue&type=script&lang=ts"

import "./CampaignSettingForm.vue?vue&type=style&index=0&id=d166f130&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-d166f130"

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QForm,QTabPanels,QTabPanel,QInput,QFooter,QToolbar,QSpace,QBtn});
